import React from 'react';
import { getUrlParams } from '../helpers/location';
import * as Api from '../apis';
import * as liff from '../apis/liff';
import { makeStyles } from '@material-ui/core/styles';
import Layout from '../components/Layout';
import useActiveSession from '../hooks/useActiveSession';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import withAuth from '../hooks/withAuth';

const useStyles = makeStyles(theme => ({
  image: {
    width: '100%',
    maxWidth: 800,
    margin: 'auto',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  buttonContainer: {
    padding: 20,
    alignItems: 'center',
  },
  fullButton: {
    width: 300,
    margin: 10,
  },
  redButton: {
    background: 'red',
    color: 'white',
    '&:hover': {
      background: 'red',
    },
  },
}));

const RequestContactPage = ({ location }) => {
  const classes = useStyles();
  const query = getUrlParams(location.search);
  const { user_question_id = '' } = query;

  useActiveSession(() => {
    Api.postCountData('contact', user_question_id);
  });

  function requestContact() {
    Api.patchUserQuestionnaire(user_question_id, { need_contact: true }).then(
      () => {
        alert('แจ้งความจำนงเป็นที่เรียบร้อย');
        if (liff.isInClient()) {
          liff.closeWindow();
        }
      },
    );
  }

  function call1323() {
    Api.postCountData('call_1323').then(data => {
      window.open('tel:1323', '_self');
    });
  }

  function call027139793() {
    Api.postCountData('call_027139793').then(data => {
      window.open('tel:02-713-6793', '_self');
    });
  }

  return (
    <Layout>
      <div className={classes.container}>
        <img src='/img/request-contact.png' className={classes.image} />
      </div>
      <Grid
        container
        justify='center'
        direction='column'
        className={classes.buttonContainer}
      >
        <Grid item xs={12}>
          <Button
            className={classes.fullButton}
            onClick={call1323}
            variant='contained'
            color='secondary'
          >
            โทร 1323
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button
            className={classes.fullButton}
            onClick={call027139793}
            variant='contained'
            color='primary'
          >
            โทร 02-713-6793
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button
            className={`${classes.fullButton} ${classes.redButton}`}
            onClick={requestContact}
            variant='contained'
          >
            รอการติดต่อกลับ
          </Button>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default withAuth(RequestContactPage);
