import React, { useEffect } from 'react';
import { navigate } from 'gatsby';
import { useSelector } from 'react-redux';

export default Component => {
  return props => {
    const { isReady, user } = useSelector(state => state.session);
    useEffect(() => {
      if (isReady && !user) {
        const { pathname, search } = window.location;
        localStorage.setItem('returnUri', `${pathname}${search}`);
        navigate('/login', { replace: true });
      }
    }, [isReady, user]);
    return <Component {...props} />;
  };
};
